<template>
  <div
    id="contents"
    :class="{ spare_modi: tabIndex == 0, spare_new: tabIndex == 1 }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <AsideSelectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToSpareManagementPage'"
          :options="[
            { title: '조회&수정', name: 'modification' },
            { title: '등록', name: 'new' },
          ]"
        />
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            @click="ToggleManagementMode()"
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            v-if="isPermissionOn && tabIndex == 0"
            href="#"
            >관리</a
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">조회&수정</a>
          </li>
          <li :class="{ active: tabIndex == 1 }" v-if="isPermissionOn">
            <a @click="SetOpenTabIndex(1)">등록</a>
          </li>
        </ul>
      </div>
      <SpareModificationForm v-if="tabIndex == 0" />
      <SpareNewForm v-if="tabIndex == 1" />
    </div>
  </div>
</template>

<script>
import SpareModificationForm from '@/views/forms/Machine/Spare/SpareModificationForm.vue';
import SpareNewForm from '@/views/forms/Machine/Spare/SpareNewForm.vue';

import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    SpareModificationForm,
    SpareNewForm,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromSpareManagementPage',
      tabIndex: 'getOpenTabIndexFromSpareManagementPage',
    }),
  },
  methods: {
    ...mapMutations({
      ToggleManagementMode: 'toggleManagementModeToSpareManagementPage',
      SetOpenTabIndex: 'setOpenTabIndexToSpareManagementPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitSpareManagementPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style></style>
