<template>
  <div class="article">
    <div class="spare_enrollment_mode">
      <div class="form">
        <form>
          <div>
            <div class="input_text">
              <label class="require">부품명</label>
              <input
                type="text"
                placeholder="내용을 입력하세요"
                :value="newData.name"
                @input="$event => (newData.name = $event.target.value)"
              />
            </div>
            <div class="input_text">
              <label>규격</label>
              <input
                type="text"
                placeholder="내용을 입력하세요"
                :value="newData.standard"
                @input="$event => (newData.standard = $event.target.value)"
              />
            </div>
            <div class="input_text add_icon">
              <label class="require">사용설비</label>
              <my-local-selectric
                :id="'machine_selectric'"
                :watch="null"
                :options="machine_options"
                @changeValue="$event => pushMachine($event)"
              ></my-local-selectric>
              <div
                class="selected_wrap"
                v-show="newData.r_part_machine_list.length > 0"
              >
                <div
                  class="selected_item"
                  v-for="(machine, index) in newData.r_part_machine_list"
                  :key="machine.machine_id"
                >
                  <span>{{
                    findInfoFromId(machines, machine.machine_id).name
                  }}</span
                  ><button
                    type="button"
                    class="delete_item"
                    @click.prevent.stop="
                      newData.r_part_machine_list.splice(index, 1)
                    "
                  ></button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="input_text">
              <label>수량</label>
              <input
                type="text"
                placeholder="내용을 입력하세요"
                :value="$makeComma(newData.quantity)"
                @input="
                  $event =>
                    $inputNumber($event, newData, 'quantity', 200000000, 0)
                "
              />
            </div>
            <div class="input_text">
              <label class="require">단위</label>
              <my-local-selectric
                :id="'unit_selectric'"
                :watch="newData.unit_id"
                :options="unit_options"
                @changeValue="$event => (newData.unit_id = $event)"
              ></my-local-selectric>
            </div>

            <div class="input_text">
              <label>보관위치</label>
              <my-local-selectric
                :id="'store_selectric'"
                :watch="newData.store_id"
                :options="store_options"
                @changeValue="
                  $event => {
                    newData.store_location_id = null;
                    newData.store_id = $event;
                  }
                "
              ></my-local-selectric>
            </div>
            <div class="input_text">
              <label>세부위치</label>
              <my-local-selectric
                :id="'store_location_selectric'"
                :watch="newData.store_location_id"
                :options="store_location_options"
                @changeValue="$event => (newData.store_location_id = $event)"
              ></my-local-selectric>
            </div>
          </div>
          <div class="btn_wrap">
            <button class="btn_sub2" @click.prevent="submitForm()">
              등록
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import FETCH_MIXIN from '@/mixins/fetch';
import MODAL_MIXIN from '@/mixins/modal';
import { mapGetters } from 'vuex';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric.vue';
export default {
  components: {
    MyLocalSelectric,
  },
  mixins: [FETCH_MIXIN, MODAL_MIXIN],
  computed: {
    ...mapGetters({
      newData: 'getNewDataFromSpareManagementForm',
      unit_options: 'getUnitForSelectric',
      store_options: 'getStoresForSelectric',
      stores: 'getStores',
      machines: 'getMachines',
    }),
    machine_options() {
      if (this.machines.length < 1) {
        return [{ label: '선택', value: null, detail: null }];
      } else {
        return [{ label: '선택', value: null, detail: null }].concat(
          this.machines.map(x => {
            return {
              value: x.id,
              label: x.name,
              detail: x.detail,
            };
          }),
        );
      }
    },
    store_location_options() {
      if (this.stores.length < 1 || this.newData.store_id == null) {
        return [{ value: null, label: '선택', detail: null }];
      } else {
        const selectedStore = this.stores.find(
          x => x.id == this.newData.store_id,
        );
        if (selectedStore != undefined) {
          return [{ value: null, label: '선택', detail: null }].concat(
            selectedStore.locations.map(y => {
              y.label = y.name;
              y.value = y.id;
              return y;
            }),
          );
        } else {
          return [{ value: null, label: '선택', detail: null }];
        }
      }
    },
    isValidSubmit() {
      if (this.newData.name.trim() == '') {
        return { flag: false, detail: 'name' };
      } else if (this.newData.r_part_machine_list.length < 1) {
        return { flag: false, detail: 'machine' };
      } else if (this.newData.unit_id == null) {
        return { flag: false, detail: 'unit' };
      } else return { flag: true };
    },
  },
  methods: {
    async submitForm() {
      if (this.isValidSubmit.flag) {
        const payload = this.lodash.clonedeep(this.newData);
        payload.quantity = this.$makeNumber(payload.quantity);
        await this.$store
          .dispatch('INSERT_SPARE_PART', payload)
          .then(async response => {
            console.log(response);
            await this.FETCH('FETCH_SPARE_PART', '스페어부품');
            this.$store.commit('setNewDataToSpareManagementForm', {
              // 등록
              name: '', //String
              standard: '', //String
              unit_id: null, //Number,
              store_id: null, //Number
              store_location_id: null, //Number
              r_part_machine_list: [], // { machine_id : number }[]
              quantity: 0, //Number,
            });
            this.openOneButtonModal(
              '등록 성공',
              '신규 스페어 부품을 등록하였습니다.',
            );
          })
          .catch(() => {
            this.openOneButtonModal('등록 실패', '스페어 등록 중 오류 발생');
          });
      } else {
        if (this.isValidSubmit.detail == 'name') {
          this.openOneButtonModal('등록 불가', '부품명은 공백일 수 없습니다.');
        } else if (this.isValidSubmit.detail == 'machine') {
          this.openOneButtonModal(
            '등록 불가',
            '최소 하나의 설비와 연결하여 주십시오.',
          );
        } else if (this.isValidSubmit.detail == 'unit') {
          this.openOneButtonModal('등록 불가', '단위는 필수 설정값입니다.');
        }
      }
    },
    pushMachine(id) {
      if (
        id != null &&
        this.newData.r_part_machine_list.findIndex(x => x.machine_id == id) < 0
      ) {
        this.newData.r_part_machine_list.push({ machine_id: id });
      }
    },
  },

  async created() {
    if (this.machines.length < 1) {
      await this.FETCH('FETCH_MACHINE', '설비');
    }
    if (this.unit_options.length < 2) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.stores.length < 1) {
      await this.FETCH('FETCH_STORE', '창고');
    }
  },
};
</script>

<style scoped></style>
