<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <div class="input_search">
            <input
              type="text"
              placeholder="설비기기 검색"
              :value="strSearchMachine"
              @input="
                $event =>
                  $store.commit(
                    'setStrSearchMachineToSpareManagementForm',
                    $event.target.value,
                  )
              "
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
          <div class="input_search">
            <input
              type="text"
              placeholder="스페어 부품 검색"
              :value="strSearchSparePart"
              @input="
                $event =>
                  $store.commit(
                    'setStrSearchSparePartToSpareManagementForm',
                    $event.target.value,
                  )
              "
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
          <div class="input_checkbox">
            <label
              class="chk_box"
              :class="{
                active: chkOnlyNotZeroQuantity,
              }"
              @click="
                $store.commit(
                  'setChkOnlyNoyZeroQuantityToSpareManagementForm',
                  !chkOnlyNotZeroQuantity,
                )
              "
            >
              <i class="fa fa-check"></i>
            </label>
            <input type="checkbox" id="checkbox1" />
            <label
              for="checkbox1"
              class="label_txt"
              @click="
                $store.commit(
                  'setChkOnlyNoyZeroQuantityToSpareManagementForm',
                  !chkOnlyNotZeroQuantity,
                )
              "
              >수량 있는 부품만 보기</label
            >
          </div>
        </div>
      </div>
      <h6>조회수 : {{ spare_parts.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>No.</th>
              <th>부품명</th>
              <th>규격</th>
              <th>수량</th>
              <th>단위</th>
              <th>보관위치</th>
              <th>세부위치</th>
              <th>설비기기 명</th>
              <th>설비점검기기 위치</th>
              <th v-show="managementMode">설비연동 제거</th>
              <th v-show="managementMode">부품 제거</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(part, index) in display_arr"
              :key="part.id"
              :class="{ new: part.id < 0 }"
            >
              <td v-if="part.row_span != null" :rowspan="part.row_span">
                {{ spare_parts.findIndex(x => x.id == part.part_id) + 1 }}
              </td>

              <td v-if="part.row_span != undefined" :rowspan="part.row_span">
                <span v-if="part.mod_name" class="check">
                  <input
                    v-if="part.mod_name"
                    type="text"
                    :value="part.name_temp"
                    @input="$event => (part.name_temp = $event.target.value)"
                  />
                  <button
                    v-if="managementMode && part.mod_name"
                    @click="changeNameAll(part)"
                  >
                    <font-awesome-icon :icon="['fas', 'fa-circle-check']" />
                  </button>
                </span>
                <span v-else>
                  {{ part.part_name }}
                  <button
                    v-if="managementMode && !part.mod_name"
                    @click.prevent="toggleModName(part)"
                  >
                    <font-awesome-icon :icon="['fas', 'fa-pen-to-square']" />
                  </button>
                </span>
              </td>
              <td v-if="part.row_span != undefined" :rowspan="part.row_span">
                <span v-if="part.mod_standard" class="check">
                  <input
                    v-if="part.mod_standard"
                    type="text"
                    :value="part.standard_temp"
                    @input="
                      $event => (part.standard_temp = $event.target.value)
                    "
                  />
                  <button
                    v-if="managementMode && part.mod_standard"
                    @click="changeStandardAll(part)"
                  >
                    <font-awesome-icon :icon="['fas', 'fa-circle-check']" />
                  </button>
                </span>
                <span v-else>
                  {{ part.part_standard }}
                  <button
                    v-if="managementMode && !part.mod_standard"
                    @click.prevent="toggleModStandard(part)"
                  >
                    <font-awesome-icon :icon="['fas', 'fa-pen-to-square']" />
                  </button>
                </span>
              </td>
              <td
                class="text_right"
                v-if="part.row_span != undefined"
                :rowspan="part.row_span"
              >
                <span v-if="part.mod_quantity" class="check">
                  <input
                    v-if="part.mod_quantity"
                    type="text"
                    :value="$makeComma(part.quantity_temp)"
                    @input="
                      $event =>
                        $inputNumber(
                          $event,
                          part,
                          'quantity_temp',
                          200000000,
                          0,
                        )
                    "
                  />
                  <button
                    v-if="managementMode && part.mod_quantity"
                    @click="changeQuantityAll(part)"
                  >
                    <font-awesome-icon :icon="['fas', 'fa-circle-check']" />
                  </button>
                </span>
                <span v-else>
                  {{ $makeComma(part.quantity) }}
                  <button
                    v-if="managementMode && !part.mod_quantity"
                    @click.prevent="toggleModQuantity(part)"
                  >
                    <font-awesome-icon :icon="['fas', 'fa-pen-to-square']" />
                  </button>
                </span>
              </td>
              <td v-if="part.row_span != undefined" :rowspan="part.row_span">
                {{
                  managementMode ? '' : findInfoFromId(units, part.unit_id).name
                }}
                <div class="select_wrap" v-show="managementMode">
                  <my-local-selectric
                    :id="`unit_selectric_${index}`"
                    :watch="part.unit_id"
                    :options="unit_options.filter(x => x.value != null)"
                    @changeValue="$event => chnageUnitAll(part, $event)"
                  ></my-local-selectric>
                </div>
              </td>

              <td v-if="part.row_span != undefined" :rowspan="part.row_span">
                {{
                  managementMode
                    ? ''
                    : findInfoFromId(stores, part.store_id).name
                }}
                <div class="select_wrap" v-show="managementMode">
                  <my-local-selectric
                    :id="`store_selectric_${index}`"
                    :watch="part.store_id"
                    :options="store_options"
                    @changeValue="$event => chnageStoreAll(part, $event)"
                  ></my-local-selectric>
                </div>
              </td>
              <td v-if="part.row_span != undefined" :rowspan="part.row_span">
                {{
                  managementMode
                    ? ''
                    : findInfoFromId(locations, part.store_location_id).name
                }}
                <my-local-selectric
                  v-if="managementMode"
                  :id="`store_location_selectric_${index}`"
                  :watch="part.store_location_id"
                  :options="store_location_options(part.store_id)"
                  @changeValue="$event => chnageStoreLocationAll(part, $event)"
                ></my-local-selectric>
              </td>
              <td :class="{ not_row: !part.row_span }">
                {{
                  managementMode
                    ? ''
                    : findInfoFromId(machines, part.machine_id).name
                }}
                <my-local-selectric
                  :id="`machine_selectric_${index}`"
                  v-if="managementMode"
                  :watch="part.machine_id"
                  :options="
                    part.id < 0
                      ? machine_options.map(x => {
                          if (x.value == null) {
                            x.label = '신규 선택';
                          }
                          return x;
                        })
                      : machine_options.filter((x, index) => {
                          if (x.value != null) {
                            return true;
                          } else return false;
                        })
                  "
                  @changeValue="$event => chnageMachine(part, $event)"
                ></my-local-selectric>
              </td>
              <td>
                {{ findInfoFromId(machines, part.machine_id).machine_location }}
              </td>
              <!-- :style="part.id < 0 ? 'background-color : #f6fafd;' : ''" -->
              <td v-show="managementMode">
                <button
                  class="tbl_delete_btn"
                  v-if="part.id >= 0"
                  @click="deleteMachine(part)"
                >
                  delete
                </button>
              </td>
              <td
                v-show="managementMode && part.row_span != null"
                :rowspan="part.row_span"
              >
                <button
                  class="tbl_delete_btn"
                  @click="
                    () => {
                      showTwoButtonModal = true;
                      delIdTemp = part.part_id;
                    }
                  "
                >
                  delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <two-button-modal
      :modal_title="'경고'"
      :modal_content="'부품을 정말로 삭제하시겠습니까?'"
      :isModalOpen="showTwoButtonModal"
      @onclose="
        showTwoButtonModal = false;
        delIdTemp = -1;
      "
      @OnYesClick="
        showTwoButtonModal = false;
        deletePart(delIdTemp);
      "
    ></two-button-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import MODAL_MIXIN from '@/mixins/modal';
import SPINNER_MIXIN from '@/mixins/spinner';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric.vue';
import TwoButtonModal from '@/layouts/components/TwoButtonModal.vue';

export default {
  mixins: [FETCH_MIXIN, MODAL_MIXIN, SPINNER_MIXIN],
  components: {
    TwoButtonModal,
    MyLocalSelectric,
  },
  data() {
    return {
      showTwoButtonModal: false,
      delIdTemp: -1,
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromSpareManagementPage',
      spare_parts: 'getSparePartFromSpareManagementForm',
      managementData: 'getManagementDataFromSpareManagementForm',
      unit_options: 'getUnitForSelectric',
      units: 'getUnitCodes',
      store_options: 'getStoresForSelectric',
      stores: 'getStores',
      machines: 'getMachines',
      strSearchMachine: 'getStrSearchMachineFromSpareManagementForm',
      strSearchSparePart: 'getStrSearchSparePartFromSpareManagementForm',
      chkOnlyNotZeroQuantity:
        'getChkOnlyNoyZeroQuantityFromSpareManagementForm',
    }),
    machine_options() {
      if (this.machines.length < 1) {
        return [{ label: '선택', value: null, detail: null }];
      } else {
        return [{ label: '선택', value: null, detail: null }].concat(
          this.machines.map(x => {
            return {
              value: x.id,
              label: x.name,
              detail: x.detail,
            };
          }),
        );
      }
    },

    locations() {
      if (this.stores.length < 1) {
        return [];
      } else {
        return this.stores.map(x => x.locations).reduce((a, b) => a.concat(b));
      }
    },
    display_arr() {
      if (this.spare_parts.length < 1 || this.machines.length < 1) {
        return [];
      } else {
        let src_arr = this.lodash.clonedeep(this.spare_parts);
        // filter Array
        src_arr = src_arr.filter(x => x.name.includes(this.strSearchSparePart));
        src_arr = src_arr.filter(x => {
          const mapMachine = x.r_part_machine_list;
          const foundIdxTemp = mapMachine.findIndex(y =>
            this.findInfoFromId(this.machines, y.machine_id).name.includes(
              this.strSearchMachine,
            ),
          );
          if (foundIdxTemp >= 0) {
            return true;
          } else return false;
        });
        if (this.chkOnlyNotZeroQuantity) {
          src_arr = src_arr.filter(x => x.quantity > 0);
        }

        const rst_arr = [];
        src_arr.map(el => {
          rst_arr.push(
            el.r_part_machine_list.map(y => {
              y.name_temp = el.name_temp;
              y.standard_temp = el.standard_temp;
              y.quantity_temp = el.quantity_temp;
              y.quantity = el.quantity;
              y.part_name = el.name;
              y.part_standard = el.standard;
              y.store_id = el.store_id;
              y.store_location_id = el.store_location_id;
              y.unit_id = el.unit_id;
              y.mod_name = el.mod_name;
              y.mod_standard = el.mod_standard;
              y.mod_quantity = el.mod_quantity;
              return y;
            }),
          );
          if (this.managementMode) {
            const copyTemp = this.lodash.clonedeep(
              rst_arr[rst_arr.length - 1][0],
            );
            copyTemp.id *= -1;
            copyTemp.machine_id = null;

            rst_arr[rst_arr.length - 1].push(copyTemp);
          }
          return el;
        });

        return rst_arr.reduce((a, b) => {
          if (b.length > 0) {
            b[0].row_span = b.length;
            return a.concat(b);
          } else {
            return a;
          }
        }, []);
      }
    },
  },
  methods: {
    async changeNameAll(part) {
      this.showSpinner();
      console.log(part);
      const foundSP = this.spare_parts.find(x => x.id == part.part_id);
      if (foundSP != undefined) {
        const payload = this.lodash.clonedeep(foundSP);
        payload.name = part.name_temp;
        this.$store
          .dispatch('UPDATE_SPARE_PART', payload)
          .then(async () => {
            this.toggleModName(part);
            await this.FETCH('FETCH_SPARE_PART');
          })
          .catch(() => {
            this.openOneButtonModal('수정 실패', '수정 중 오류발생');
            foundSP.name_temp = this.foundSP.name;
            part.name_temp = part.part_name;
            foundSP.mod_name = false;
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    async changeStandardAll(part) {
      this.showSpinner();
      console.log(part);
      const foundSP = this.spare_parts.find(x => x.id == part.part_id);
      if (foundSP != undefined) {
        const payload = this.lodash.clonedeep(foundSP);
        payload.standard = part.standard_temp;
        this.$store
          .dispatch('UPDATE_SPARE_PART', payload)
          .then(async () => {
            // this.toggleModStandard(part);
            await this.FETCH('FETCH_SPARE_PART');
          })
          .catch(() => {
            this.openOneButtonModal('수정 실패', '수정 중 오류발생');
            foundSP.standard_temp = this.foundSP.standard;
            part.standard_temp = part.part_standard;
            foundSP.mod_standard = false;
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    async changeQuantityAll(part) {
      this.showSpinner();
      console.log(part);
      const foundSP = this.spare_parts.find(x => x.id == part.part_id);
      if (foundSP != undefined) {
        const payload = this.lodash.clonedeep(foundSP);
        payload.quantity = this.$makeNumber(part.quantity_temp);
        this.$store
          .dispatch('UPDATE_SPARE_PART', payload)
          .then(async () => {
            await this.FETCH('FETCH_SPARE_PART');
          })
          .catch(() => {
            this.openOneButtonModal('수정 실패', '수정 중 오류발생');
            foundSP.quantity_temp = this.foundSP.quantity;
            part.quantity_temp = part.quantity;
            foundSP.mod_quantity = false;
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    toggleModName(part) {
      const spareSource = this.spare_parts.find(x => x.id == part.part_id);
      if (spareSource != undefined) {
        spareSource.mod_name = !spareSource.mod_name;
      }
    },
    toggleModStandard(part) {
      const spareSource = this.spare_parts.find(x => x.id == part.part_id);
      if (spareSource != undefined) {
        spareSource.mod_standard = !spareSource.mod_standard;
      }
    },
    toggleModQuantity(part) {
      const spareSource = this.spare_parts.find(x => x.id == part.part_id);
      if (spareSource != undefined) {
        spareSource.mod_quantity = !spareSource.mod_quantity;
      }
    },
    store_location_options(id) {
      if (this.stores.length < 1 || id == null) {
        return [{ value: null, label: '선택', detail: null }];
      } else {
        const selectedStore = this.stores.find(x => x.id == id);
        if (selectedStore != undefined) {
          return [{ value: null, label: '선택', detail: null }].concat(
            selectedStore.locations.map(y => {
              y.label = y.name;
              y.value = y.id;
              return y;
            }),
          );
        } else {
          return [{ value: null, label: '선택', detail: null }];
        }
      }
    },
    async deletePart(del_id) {
      this.showSpinner();
      await this.$store
        .dispatch('DELETE_SPARE_PART', del_id)
        .then(async () => {
          await this.FETCH('FETCH_SPARE_PART');
        })
        .catch(() => {
          this.openOneButtonModal('삭제 실패', '삭제 중 오류발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async deleteMachine(part) {
      const foundSP = this.spare_parts.find(x => x.id == part.part_id);
      if (foundSP != undefined) {
        console.log(foundSP);

        const payload = this.lodash.clonedeep(foundSP);
        if (payload.r_part_machine_list.length == 1) {
          this.openOneButtonModal(
            '삭제 불가',
            '스페어 부품은 최소 하나 이상 설비와 연결되어야합니다.',
          );
          return;
        }
        const foundIdx = payload.r_part_machine_list.findIndex(
          x => x.id == part.id,
        );
        payload.r_part_machine_list.splice(foundIdx, 1);
        this.showSpinner();
        this.$store
          .dispatch('UPDATE_SPARE_PART', payload)
          .then(async () => {
            await this.FETCH('FETCH_SPARE_PART');
          })
          .catch(() => {
            this.openOneButtonModal('삭제 실패', '삭제 중 오류발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    async chnageMachine(part, new_id) {
      if (new_id == null) {
        return;
      }
      console.log(part, new_id);
      const foundSP = this.spare_parts.find(x => x.id == part.part_id);
      if (foundSP != undefined) {
        console.log(foundSP);
        const exist_machine_list = foundSP.r_part_machine_list;
        if (
          exist_machine_list.length == 0 ||
          exist_machine_list.findIndex(x => x.machine_id == new_id) < 0
        ) {
          const payload = this.lodash.clonedeep(foundSP);
          if (part.id < 0) {
            payload.r_part_machine_list.push({ machine_id: new_id });
          } else {
            payload.r_part_machine_list.find(
              x => x.id == part.id,
            ).machine_id = new_id;
          }
          this.showSpinner();
          this.$store
            .dispatch('UPDATE_SPARE_PART', payload)
            .then(async () => {
              await this.FETCH('FETCH_SPARE_PART');
            })
            .catch(() => {
              this.openOneButtonModal(
                '등록/수정 실패',
                '등록/수정 중 오류발생',
              );
            })
            .finally(() => {
              this.hideSpinner();
            });
        } else {
          if (part.id < 0) {
            this.openOneButtonModal(
              '등록 불가',
              '이미 해당 설비와 연결관계가 있습니다.',
            );
          } else {
            this.openOneButtonModal(
              '수정 불가',
              '이미 해당 설비와 연결관계가 있습니다.',
            );
          }
        }
      }
    },
    async chnageStoreLocationAll(part, new_id) {
      this.showSpinner();

      const foundSP = this.spare_parts.find(x => x.id == part.part_id);
      if (foundSP != undefined) {
        const payload = this.lodash.clonedeep(foundSP);

        payload.store_location_id = new_id;
        this.$store
          .dispatch('UPDATE_SPARE_PART', payload)
          .then(async () => {
            await this.FETCH('FETCH_SPARE_PART');
          })
          .catch(() => {
            this.openOneButtonModal('수정 실패', '수정 중 오류발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    async chnageStoreAll(part, new_id) {
      this.showSpinner();

      const foundSP = this.spare_parts.find(x => x.id == part.part_id);
      if (foundSP != undefined) {
        const payload = this.lodash.clonedeep(foundSP);
        payload.store_id = new_id;
        payload.store_location_id = null;
        this.$store
          .dispatch('UPDATE_SPARE_PART', payload)
          .then(async () => {
            await this.FETCH('FETCH_SPARE_PART');
          })
          .catch(() => {
            this.openOneButtonModal('수정 실패', '수정 중 오류발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    async chnageUnitAll(part, new_id) {
      this.showSpinner();

      const foundSP = this.spare_parts.find(x => x.id == part.part_id);
      if (foundSP != undefined) {
        const payload = this.lodash.clonedeep(foundSP);
        payload.unit_id = new_id;
        this.$store
          .dispatch('UPDATE_SPARE_PART', payload)
          .then(async () => {
            await this.FETCH('FETCH_SPARE_PART');
          })
          .catch(() => {
            this.openOneButtonModal('수정 실패', '수정 중 오류발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
  },
  async created() {
    if (this.spare_parts.length < 1) {
      await this.FETCH('FETCH_SPARE_PART');
    }
    if (this.machines.length < 1) {
      await this.FETCH('FETCH_MACHINE', '설비');
    }
    if (this.unit_options.length < 2) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.stores.length < 1) {
      await this.FETCH('FETCH_STORE', '창고');
    }
  },
};
</script>

<style></style>
